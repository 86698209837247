import ApiService from "../api.service";
import JwtService from "../jwt.service";

export const DADOS_AUTENTICACAO = "dadosAutenticacao";

const state = {
  usuario: {},
  menus: [],
  permissoes: [],
  autenticado: !!JwtService.obterToken(),
};

const getters = {
  usuarioAutenticado(state) {
    return state.usuario;
  },

  menus(state) {
    return state.menus;
  },

  permissoes(state) {
    return state.permissoes;
  },

  estaAutenticado(state) {
    return state.autenticado;
  },
};

const actions = {
  [DADOS_AUTENTICACAO](context, dados) {
    if (dados) {
      context.commit("setarDados", dados);
    } else {
      context.commit("removerDados");
    }
  },
};

const mutations = {
  setarDados(state, { usuario, token, menus, permissoes }) {
    state.usuario = usuario;
    state.menus = menus;
    state.permissoes = permissoes;
    state.autenticado = !!token;
    JwtService.salvarToken(token);
    ApiService.setHeader();
  },

  removerDados(state) {
    state.usuario = {};
    state.menus = [];
    state.permissoes = [];
    state.autenticado = false;
    JwtService.excluirToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
