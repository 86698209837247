import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/inicio",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/inicio",
          name: "inicio",
          component: () => import("@/view/pages/inicio/Inicio.vue"),
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/usuarios",
          name: "usuarios",
          component: () => import("@/view/pages/usuarios/UsuarioListar.vue"),
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/formularios",
          name: "formularios",
          component: () =>
            import("@/view/pages/formularios/FormularioListar.vue"),
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/clientes",
          name: "clientes",
          component: () => import("@/view/pages/clientes/ClienteListar.vue"),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/autenticacao/Autenticacao.vue"),
      children: [
        {
          name: "autenticar",
          path: "/autenticar",
          component: () => import("@/view/pages/autenticacao/Autenticar.vue"),
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/erro/Erro.vue"),
    },
  ],
});
