import ApiService from "./api.service";
import JwtService from "./jwt.service";

const AutenticacaoService = {
  autenticar(credenciais) {
    return ApiService.post("autenticar", credenciais);
  },

  desconectar() {
    return ApiService.get("desconectar");
  },

  verificarAutenticacao() {
    const token = JwtService.obterToken();

    if (token) {
      ApiService.setHeader();

      return ApiService.get("verificar-autenticacao");
    } else {
      return new Promise((resolve) => resolve({ data: null }));
    }
  },
};

export default AutenticacaoService;
