const ID_TOKEN_KEY = "id_token";

export const obterToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const salvarToken = (token) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const excluirToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { obterToken, salvarToken, excluirToken };
